<template>
  <RevContainer class="pb-24 pt-40 md:py-72">
    <div class="grid grid-cols-1 md:grid-cols-2 md:gap-56">
      <div class="text-static-default-hi flex max-w-[600px] flex-col">
        <RevIllustration
          alt=""
          class="block h-[140px] w-[137px] self-center md:mb-18 lg:self-start"
          data-qa="payment-success-gif"
          :height="140"
          :src="props.illustrationPath"
          :width="137"
        />
        <h2 class="body-1">{{ props.subtitle }}</h2>
        <span class="heading-1 mb-18"> {{ props.title }} </span>

        <slot name="content">
          <div v-if="props.description" class="body-1 mb-16">
            {{ props.description }}
          </div>
        </slot>
      </div>
      <div>
        <slot name="aside">
          <MobileAppCard v-if="features.mobileApp.available" />
        </slot>
      </div>
    </div>
  </RevContainer>
</template>

<script setup lang="ts">
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { RevContainer } from '@ds/components/Container'
import { RevIllustration } from '@ds/components/Illustration'

import type { Features } from '~/features'
import MobileAppCard from '~/scopes/checkout/components/Result/payment-success/components/PaymentSuccessHeader/components/MobileAppCard/MobileAppCard.vue'

const props = withDefaults(
  defineProps<{
    title: string
    subtitle: string
    description?: string
    illustrationPath?: string
  }>(),
  {
    illustrationPath: '/img/payment/paymentResultThankYou.svg',
    description: undefined,
  },
)

const { features } = useMarketplace<Features>()
</script>
