import translations from './PaymentSuccess.config.translations'

export const PAYMENT_RESULT_CARD_TYPES = {
  ABOUT_US: 'aboutUs',
  BUYBACK: 'buyback',
  DEALS: 'deals',
  FAQ: 'faq',
  REFERRAL_FAQ: 'referral_faq',
  REFERRAL_PROGRAM: 'referral_program',
}

// Order is important as it will determine the cards that should appear by preference.
export const CARDS = [
  {
    alt: translations.discountTitle,
    content: translations.discountContent,
    cta: translations.discountAction,
    image: '/img/payment/discount.png',
    title: translations.discountTitle,
    name: 'referral_coupon',
    type: PAYMENT_RESULT_CARD_TYPES.REFERRAL_PROGRAM,
  },
  {
    alt: translations.discountTitle,
    content: translations.discountContent,
    cta: translations.discountAction,
    image: '/img/payment/discount.png',
    title: translations.discountTitle,
    name: 'referral_coupon',
    type: PAYMENT_RESULT_CARD_TYPES.REFERRAL_FAQ,
  },
  {
    alt: translations.buybackTitle,
    content: translations.buybackContent,
    cta: translations.buybackAction,
    image: '/img/payment/buyback.jpg',
    title: translations.buybackTitle,
    name: 'sell_electronic_products',
    type: PAYMENT_RESULT_CARD_TYPES.BUYBACK,
  },
  {
    alt: translations.aboutUsTitle,
    content: translations.aboutUsContent,
    cta: translations.aboutUsAction,
    image: '/img/payment/about_us.jpg',
    title: translations.aboutUsTitle,
    name: 'about',
    type: PAYMENT_RESULT_CARD_TYPES.ABOUT_US,
  },
  {
    alt: translations.goodDealsTitle,
    content: translations.goodDealsContent,
    cta: translations.goodDealsAction,
    image: '/img/payment/good_deals.png',
    title: translations.goodDealsTitle,
    name: 'good_deals',
    type: PAYMENT_RESULT_CARD_TYPES.DEALS,
  },
  {
    alt: translations.faqTitle,
    content: translations.faqContent,
    cta: translations.faqAction,
    image: '/img/payment/faq.png',
    title: translations.faqTitle,
    name: 'faq',
    type: PAYMENT_RESULT_CARD_TYPES.FAQ,
  },
]
