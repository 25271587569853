export default {
  title: {
    id: 'mobile_app_card_title',
    defaultMessage: 'Get tracking in the app',
  },
  description: {
    id: 'mobile_app_card_description',
    defaultMessage:
      'Plus exclusive care tips, deals, order tracking, and more.',
  },
  infoDesktop: {
    id: 'mobile_app_card_info_desktop',
    defaultMessage: 'Scan to join over 3 million app users.',
  },
  infoMobile: {
    id: 'mobile_app_card_info_mobile',
    defaultMessage: 'Join over 3 million app users.',
  },
}
