<template>
  <RevCard
    class="flex flex-col items-start p-24 md:flex-row md:items-center md:p-32"
  >
    <div class="mr-32 hidden w-[149px] flex-col space-y-8 md:flex">
      <RevIllustration
        alt=""
        class="min-h-[155px] min-w-[149px]"
        :height="155"
        src="/img/payment/phoneWithQrCode.png"
        :width="149"
      />

      <div
        class="bg-surface-default-hi text-static-default-hi rounded-sm body-2-bold whitespace-pre-line p-8 text-center"
      >
        {{ i18n(translations.infoDesktop) }}
      </div>
    </div>

    <div
      class="bg-surface-default-hi text-static-default-hi rounded-sm body-2-bold mb-8 w-min text-nowrap p-4 md:hidden"
    >
      {{ i18n(translations.infoMobile) }}
    </div>

    <div>
      <h3 class="text-static-default-hi body-1-bold mb-4">
        {{ props.title ?? i18n(translations.title) }}
      </h3>
      <p class="body-1 mb-16">
        {{ props.description ?? i18n(translations.description) }}
      </p>

      <AppStoreButtons
        campaign="payment_confirmation"
        class="flex items-center justify-stretch"
        tracking-zone="payment_success_app_download"
      />
    </div>
  </RevCard>
</template>

<script setup lang="ts">
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevCard } from '@ds/components/Card'
import { RevIllustration } from '@ds/components/Illustration'

import translations from './MobileAppCard.translations'

const i18n = useI18n()

const props = defineProps<{
  title?: string
  description?: string
}>()
</script>
