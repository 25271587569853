export default {
  title: {
    id: 'garbage_card_title',
    defaultMessage: '{unit} of electronic waste avoided',
  },
  subtitle: {
    id: 'garbage_card_subtitle',
    defaultMessage: 'Including the {size, html} of your order. Clap, Clap!',
  },
}
