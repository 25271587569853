<template>
  <div>
    <h2 class="heading-3 mb-24 last:mb-0 md:mb-16">
      {{ i18n(translations.title) }}
    </h2>
    <ul class="mb-7 list-outside px-16 md:mb-24">
      <li class="mb-24 last:mb-0 md:mb-16">
        <span class="body-1">
          {{ i18n(translations.tip1) }}
        </span>
      </li>
      <li class="mb-24 last:mb-0 md:mb-16">
        <span class="body-1">
          {{ i18n(translations.tip2) }}
        </span>
      </li>
      <li class="mb-24 last:mb-0 md:mb-16">
        <span class="body-1">
          {{ i18n(translations.tip3) }}
        </span>
      </li>
    </ul>

    <div class="mb-24 flex flex-col md:flex-row">
      <RevButton
        class="mb-32 mt-40 md:mb-0 md:mr-24 md:mt-0"
        variant="primary"
        @click="handleBackButtonClick"
      >
        {{ i18n(translations.backButton) }}
      </RevButton>

      <ErrorBoundary :owners="['bot-squad-payin-front']">
        <PaymentIcons />
      </ErrorBoundary>
    </div>

    <p>
      <span class="text-static-default-mid body-2">
        {{ i18n(translations.footer) }}
      </span>
    </p>
  </div>
</template>

<script setup lang="ts">
import { useRouter } from '#imports'

import { PaymentIcons } from '@backmarket/nuxt-layer-payment/methods'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import ErrorBoundary from '@backmarket/nuxt-module-logger/components/ErrorBoundary.vue'
import { RevButton } from '@ds/components/Button'

import { CHECKOUT } from '~/scopes/checkout/routes-names'
import { INSURANCE } from '~/scopes/insurance/routes.constants'

import translations from './FailureInstructions.translations'

const i18n = useI18n()
const router = useRouter()

const handleBackButtonClick = () => {
  const route = router.currentRoute.value
  if (route.name === INSURANCE.DUE_PAYMENT_RESULT) {
    router.push({ name: INSURANCE.DUE_PAYMENT, params: route.params })
  } else {
    router.push({ name: CHECKOUT.PAYMENT })
  }
}
</script>
