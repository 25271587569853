<template>
  <div v-if="paymentId">
    <PaymentSuccessHeaderBase
      class="bg-static-success-low"
      :description="i18n(translations.description)"
      :subtitle="i18n(translations.subtitle)"
      :title="i18n(translations.title)"
    >
      <template #aside>
        <MobileAppCard
          v-if="features.mobileApp.available"
          :description="i18n(translations.appCardDescription)"
          :title="i18n(translations.appCardTitle)"
        />
      </template>
    </PaymentSuccessHeaderBase>

    <div class="my-36 md:my-56">
      <PaymentSuccessFooter />
    </div>
  </div>
  <div v-else>
    <PaymentFailure />
  </div>
</template>

<script setup lang="ts">
import { useRoute } from '#imports'
import { computed } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'

import type { Features } from '~/features'
import PaymentFailure from '~/scopes/checkout/components/Result/payment-error/PaymentFailure/PaymentFailure.vue'
import PaymentSuccessFooter from '~/scopes/checkout/components/Result/payment-success/components/PaymentSuccessFooter/PaymentSuccessFooter.vue'
import MobileAppCard from '~/scopes/checkout/components/Result/payment-success/components/PaymentSuccessHeader/components/MobileAppCard/MobileAppCard.vue'
import PaymentSuccessHeaderBase from '~/scopes/checkout/components/Result/payment-success/components/PaymentSuccessHeaderBase/PaymentSuccessHeaderBase.vue'

import translations from './DueInsurancePaymentResult.translations'

const route = useRoute()
const paymentId = computed(() =>
  route.query.paymentId ? String(route.query.paymentId) : undefined,
)
const i18n = useI18n()
const { features } = useMarketplace<Features>()
</script>
