export default {
  relatedTitle: {
    id: 'payment_result_page_related_title',
    defaultMessage: 'This might interest you',
  },
  relatedProducts: {
    id: 'payment_result_page_related_products',
    defaultMessage: 'Related products to your order',
  },
}
