export default {
  subtitle: {
    id: 'new_payment_result_failure_subtitle',
    defaultMessage: 'Heavens!',
  },
  title: {
    id: 'new_payment_result_failure_title',
    defaultMessage: 'Payment has failed',
  },
  description: {
    id: 'new_payment_result_failure_description',
    defaultMessage:
      "We're sorry, but your payment didn't work. Here is the error message that our secure payment system sent us:",
  },
  defaultDescription: {
    id: 'new_payment_result_failure_description_default',
    defaultMessage: "We're sorry, but your payment didn't work.",
  },
}
