<template>
  <div class="lg:flex lg:min-h-[50vh] lg:flex-col">
    <RevContainer class="bg-static-danger-hi py-24 lg:grow lg:py-56">
      <div class="grid-standard">
        <header class="flex flex-col md:col-span-3 lg:col-span-5">
          <RevIllustration
            alt=""
            class="-mb-16 block size-[192px] self-center md:-mb-[83.2px] lg:mb-12 lg:self-start"
            :height="120"
            src="/img/payment/paymentResultFailure.svg"
            :width="120"
          />

          <span class="body-1 mb-4">
            {{ i18n(translations.subtitle) }}
          </span>

          <h1 class="heading-1 mb-16">
            {{ i18n(translations.title) }}
          </h1>

          <template v-if="payment?.errorMessage">
            <p class="body-1 mb-16">
              {{ i18n(translations.description) }}
            </p>

            <p class="mb-12">
              <RevTag :label="payment.errorMessage" variant="info" />
            </p>
          </template>

          <p v-if="!payment?.errorMessage" class="body-1 mb-16">
            {{ i18n(translations.defaultDescription) }}
          </p>
        </header>
        <RevCard class="hidden lg:col-span-6 lg:col-start-7 lg:block lg:p-24">
          <FailureInstructions />
        </RevCard>
      </div>
    </RevContainer>

    <RevContainer class="bg-static-default-mid py-32 md:py-24 lg:hidden">
      <FailureInstructions />
    </RevContainer>
  </div>
</template>

<script setup lang="ts">
import type { PaymentResult } from '@backmarket/http-api/src/api-specs-payment/payment/payment-result.types'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevCard } from '@ds/components/Card'
import { RevContainer } from '@ds/components/Container'
import { RevIllustration } from '@ds/components/Illustration'
import { RevTag } from '@ds/components/Tag'

import translations from './PaymentFailure.translations'
import FailureInstructions from './components/FailureInstructions/FailureInstructions.vue'

const i18n = useI18n()

defineProps<{
  payment?: PaymentResult
}>()
</script>
