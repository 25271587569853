export default {
  title: {
    id: 'payment_result_failure_instructions_title',
    defaultMessage: 'What to do?',
  },
  tip1: {
    id: 'payment_result_failure_instructions_tip1',
    defaultMessage: 'Try paying with another card.',
  },
  tip2: {
    id: 'payment_result_failure_instructions_tip2',
    defaultMessage: 'Try another payment method.',
  },
  tip3: {
    id: 'payment_result_failure_instructions_tip3',
    defaultMessage: 'Contact your bank to find a solution to this problem.',
  },
  backButton: {
    id: 'payment_result_failure_instructions_back_button',
    defaultMessage: 'Back to payment',
  },
  footer: {
    id: 'payment_result_failure_instructions_footer',
    defaultMessage:
      'The security of your transaction is ensured by Adyen, the European leader in online payments, allowing you to buy securely using secure connections (https), The payment is secure, your bank details are encrypted using the SSL protocol. We have no access to your bank details at any time, and this information is not stored. You may pay by debit card, Visa, MasterCard.',
  },
}
