<template>
  <RevCard
    class="!bg-static-default-mid text-static-default-mid flex h-380 flex-col items-center justify-center bg-center bg-no-repeat px-32 text-center"
    :style="{
      backgroundImage: `url(${toPublicPath('/img/checkout/planet.svg')})`,
      backgroundSize: '150% auto',
    }"
  >
    <div class="flex justify-center">
      <div
        v-for="(number, index) in wasteNumbers"
        :key="index"
        class="bg-static-default-hi rounded-sm mr-4 flex h-[46px] w-36 items-center justify-center last:mr-0"
      >
        <span class="heading-1">
          {{ number }}
        </span>
      </div>
    </div>

    <h1 class="heading-1 mt-32 break-words">
      {{ i18n(translations.title, { unit: garbageTotalUnitAndWeight.unit }) }}
    </h1>

    <div class="body-2 mt-32">
      <FormattedMessage :definition="translations.subtitle">
        <template #size>
          <span class="body-2-bold">{{ waste }}</span>
        </template>
      </FormattedMessage>
    </div>
  </RevCard>
</template>

<script setup lang="ts">
import { toPublicPath } from '#imports'
import { computed } from 'vue'

import FormattedMessage from '@backmarket/nuxt-module-i18n/FormattedMessage.vue'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevCard } from '@ds/components/Card'

import translations from './GarbageCard.translations'

const i18n = useI18n()

const props = defineProps<{
  totalWaste: number
  orderWaste: number
}>()

const gramsToUnit = (grams: number, unit: string) => {
  const RATIO = 1000

  switch (unit) {
    case 'kg':
      return Math.round(grams / RATIO)
    case 'mt':
      return Math.round(grams / RATIO / RATIO)
    default:
      return grams
  }
}

const getUnitAndWeight = (weightInGrams: number) => {
  const weightInKg = gramsToUnit(weightInGrams, 'kg')

  if (weightInKg > 1) {
    return {
      weight: weightInKg,
      formattedWeight: `${i18n.number(weightInKg)} kg`,
      unit: 'kg',
    }
  }

  return {
    weight: weightInGrams,
    formattedWeight: `${i18n.number(weightInGrams)} g`,
    unit: 'g',
  }
}

const waste = computed(() => getUnitAndWeight(props.orderWaste).formattedWeight)
const garbageTotalUnitAndWeight = computed(
  () => getUnitAndWeight(props.totalWaste) ?? '',
)
const wasteNumbers = computed(() =>
  garbageTotalUnitAndWeight.value.weight.toString().split('').slice(0, 5),
)
</script>
