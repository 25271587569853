<template>
  <div v-if="isEnabled" data-test="trusted-shops-form">
    <div id="trustedShopsCheckout" style="display: none">
      <span id="tsCheckoutOrderNr">{{ payment.paymentId }}</span>
      <span id="tsCheckoutBuyerEmail">{{ payment.client.email }}</span>
      <span id="tsCheckoutOrderAmount">
        {{ payment.amountAfterDiscount.amount }}
      </span>
      <span id="tsCheckoutOrderCurrency">
        {{ payment.amountAfterDiscount.currency }}
      </span>
      <span id="tsCheckoutOrderPaymentType">{{ payment.method.bmCode }}</span>

      <span
        v-for="product in products"
        :key="product.id"
        :class="trustedShopClasses.item"
      >
        <span :class="trustedShopClasses.url">{{ product.slug }}</span>
        <span :class="trustedShopClasses.image">{{ product.image }} </span>
        <span :class="trustedShopClasses.name">{{ product.title }}</span>
        <span :class="trustedShopClasses.sku">{{ product.sku }}</span>
        <span :class="trustedShopClasses.gtin"></span>
        <span :class="trustedShopClasses.mpn"></span>
        <span :class="trustedShopClasses.brand">{{ product.brand }}</span>
      </span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useRuntimeConfig } from '#imports'
import { computed } from 'vue'

import type { PaymentResult } from '@backmarket/http-api/src/api-specs-payment/payment/payment-result.types'

import { useTrustmark } from '../useTrustmark'

// Classes using the CSS injected by the TrustedShops script
// More details on TrustedShop Integration https://help.etrusted.com/hc/en-gb/articles/360047444811-How-can-I-integrate-the-Trustbadge-in-my-website-without-a-plugin
const trustedShopClasses = {
  item: 'tsCheckoutProductItem',
  url: 'tsCheckoutProductUrl',
  image: 'tsCheckoutProductImageUrl',
  name: 'tsCheckoutProductName',
  sku: 'tsCheckoutProductSKU',
  gtin: 'tsCheckoutProductGTIN',
  mpn: 'tsCheckoutProductMPN',
  brand: 'tsCheckoutProductBrand',
}

const props = defineProps<{ payment: PaymentResult }>()

const runtimeConfig = useRuntimeConfig()
const { withTrustedShops } = useTrustmark()

const isEnabled = computed(
  () => !runtimeConfig.public.KILL_TRUSTMARK && withTrustedShops,
)

const products = computed(() =>
  props.payment.orders
    .map((order) =>
      order.orderlines.map((orderline, index) => ({
        id: orderline.id ?? index,
        brand: orderline.product?.brand ?? '',
        image: orderline.product?.image ?? '',
        sku: orderline.product?.sku ?? '',
        slug: orderline.product?.slug ?? '',
        title: orderline.product?.title ?? '',
      })),
    )
    .flat(),
)
</script>
