<template>
  <RevContainer>
    <h3 class="heading-3">
      {{ i18n(translations.relatedTitle) }}
    </h3>

    <div class="grid-standard mt-24 items-end">
      <GarbageCard
        v-if="garbage"
        class="md:col-span-3"
        :order-waste="garbage.orderWaste"
        :total-waste="garbage.totalWaste"
      />
      <RevEditorialCard
        v-for="card in cards"
        :key="i18n(card.title)"
        :alt="i18n(card.alt)"
        class="mt-16 md:col-span-3"
        :content="i18n(card.content)"
        :cta="i18n(card.cta)"
        :image="card.image"
        :title="i18n(card.title)"
        title-tag="h1"
        :to="cardLink(card)"
        @click="() => handleClickCard(card.name)"
      />
    </div>
  </RevContainer>

  <RevContainer v-if="firstOrderlineProductId" class="mt-36 md:mt-56">
    <RecommendationCarousel
      :options="{
        withCrossedPrice: true,
        withStartingFrom: true,
        withGrade: true,
        withAddToCart: false,
      }"
      :recommendation-query="{
        category: 'cousinsUp',
        scope: 'product',
        scopeId: `${firstOrderlineProductId}`,
        limit: 4,
        personalisation: true,
      }"
      :title="i18n(translations.relatedProducts)"
    />
  </RevContainer>

  <TrustedShopsForm v-if="payment" :payment />
</template>

<script setup lang="ts">
import { useRouteLocationWithLocale } from '#imports'
import { computed, ref } from 'vue'

import type { LinkInternal } from '@backmarket/http-api'
import type { PaymentResult } from '@backmarket/http-api/src/api-specs-payment/payment/payment-result.types'
import { useUserStore } from '@backmarket/nuxt-layer-oauth/useUserStore'
import RecommendationCarousel from '@backmarket/nuxt-layer-recommendation/RecommendationCarousel.vue'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useMarketplace } from '@backmarket/nuxt-module-marketplace/useMarketplace'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { RevContainer } from '@ds/components/Container'
import { RevEditorialCard } from '@ds/components/EditorialCard'

import { type Features } from '~/features'
import { ABOUT_US } from '~/scopes/about-us/routes.constants'
import { CMS } from '~/scopes/cms/routes-names'
import { DASHBOARD } from '~/scopes/dashboard/routes'
import { HELP_CENTER } from '~/scopes/help-center/routes'
import TrustedShopsForm from '~/scopes/trustmark/TrustedShops/TrustedShopsForm.vue'

import {
  CARDS,
  PAYMENT_RESULT_CARD_TYPES,
} from '../../PaymentSuccess/PaymentSuccess.config'

import translations from './PaymentSuccessFooter.translations'
import GarbageCard from './components/GarbageCard/GarbageCard.vue'

const props = defineProps<{
  payment?: PaymentResult
}>()

const i18n = useI18n()
const tracking = useTracking()
const resolveLocalizedRoute = useRouteLocationWithLocale()
const userStore = useUserStore()
const { features } = useMarketplace<Features>()
const isBuyBackEnabled = ref(features.buyback.enabled)
const hasOrder = ref(userStore.user.numberOfOrders > 0)

const isCardEnabled = ({ type }: { type: string }) => {
  switch (type) {
    case PAYMENT_RESULT_CARD_TYPES.BUYBACK:
      return isBuyBackEnabled.value
    case PAYMENT_RESULT_CARD_TYPES.REFERRAL_PROGRAM:
      return hasOrder.value
    case PAYMENT_RESULT_CARD_TYPES.REFERRAL_FAQ:
      return (
        !isEmpty(props.payment?.links?.godfatherDiscountLearnMore) &&
        !hasOrder.value
      )
    case PAYMENT_RESULT_CARD_TYPES.DEALS:
      return !isEmpty(props.payment?.links?.bestSalesLandingPage)
    default:
      return true
  }
}

const garbage = computed(() => {
  if (!props.payment) {
    return null
  }

  return {
    orderWaste: props.payment.garbage.garbage ?? 0,
    totalWaste: props.payment.garbage.garbageTotal ?? 0,
  }
})

const cards = computed(() =>
  CARDS.filter((card) => isCardEnabled(card)).slice(0, garbage.value ? 3 : 4),
)

const cardLinks = computed(() => ({
  [PAYMENT_RESULT_CARD_TYPES.REFERRAL_PROGRAM]: {
    name: DASHBOARD.KYC.REFER_FRIEND,
  },
  // TODO: @perfect-journey
  // To remove when referral program is open in all countries
  [PAYMENT_RESULT_CARD_TYPES.REFERRAL_FAQ]:
    props.payment?.links?.godfatherDiscountLearnMore,
  [PAYMENT_RESULT_CARD_TYPES.BUYBACK]: {
    name: CMS.BUYBACK,
    params: {
      pageName: 'home',
    },
  },
  [PAYMENT_RESULT_CARD_TYPES.ABOUT_US]: { name: ABOUT_US },
  [PAYMENT_RESULT_CARD_TYPES.DEALS]: props.payment?.links?.bestSalesLandingPage,
  [PAYMENT_RESULT_CARD_TYPES.FAQ]: { name: HELP_CENTER.HOME },
}))

const cardLink = ({ type }: { type: string }) => {
  return resolveLocalizedRoute(cardLinks.value[type] as LinkInternal) || ''
}

const handleClickCard = (name: string) => {
  tracking.trackClick({
    name,
    zone: 'payment_confirmation_page',
  })
}

const firstOrderlineProductId = computed(
  () => props.payment?.orders[0]?.orderlines[0]?.product?.id ?? null,
)
</script>
