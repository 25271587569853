export default {
  subtitle: {
    id: 'due_payment_result_success_subtitle',
    defaultMessage: 'All set!',
  },
  title: {
    id: 'due_payment_result_success_title',
    defaultMessage: 'Check that off the to-do list.',
  },
  description: {
    id: 'due_payment_result_success_description',
    defaultMessage:
      'Thanks for your payment. We’ll save your updated info for next time.',
  },
  appCardTitle: {
    id: 'due_payment_success_follow_status',
    defaultMessage: 'Keep in touch with our app',
  },
  appCardDescription: {
    id: 'due_payment_result_success_install',
    defaultMessage:
      'Check your account details anytime and get alerts for our hottest deals.',
  },
}
