export default {
  discountTitle: {
    id: 'payment_result_config_cards_discount_title',
    defaultMessage: 'Godfather discount',
  },
  discountContent: {
    id: 'payment_result_config_cards_discount_content',
    defaultMessage:
      'Recommend BackMarket to your loved ones and (maybe) get some money back.',
  },
  discountAction: {
    id: 'payment_result_config_cards_discount_action',
    defaultMessage: 'How it works?',
  },
  buybackTitle: {
    id: 'payment_result_config_cards_buyback_title',
    defaultMessage: 'Sell your electronic products',
  },
  buybackContent: {
    id: 'payment_result_config_cards_buyback_content',
    defaultMessage:
      "You don't use it anymore? Sell ​​your electronic items in 2 minutes.",
  },
  buybackAction: {
    id: 'payment_result_config_cards_buyback_action',
    defaultMessage: 'Sell',
  },
  aboutUsTitle: {
    id: 'payment_result_config_cards_about_us_title',
    defaultMessage: 'About BackMarket',
  },
  aboutUsContent: {
    id: 'payment_result_config_cards_about_us_content',
    defaultMessage:
      'Our mission: to make reconditioned products as reliable as they are desirable.',
  },
  aboutUsAction: {
    id: 'payment_result_config_cards_about_us_action',
    defaultMessage: 'Learn more',
  },
  goodDealsTitle: {
    id: 'payment_result_config_cards_good_deals_title',
    defaultMessage: 'Good deals',
  },
  goodDealsContent: {
    id: 'payment_result_config_cards_good_deals_content',
    defaultMessage:
      'Smartphones, tablets, laptops, discover our in-house selection of fresh products.',
  },
  goodDealsAction: {
    id: 'payment_result_config_cards_good_deals_action',
    defaultMessage: 'Learn more',
  },
  faqTitle: {
    id: 'payment_result_config_cards_faq_title',
    defaultMessage: 'Questions? Nice answers!',
  },
  faqContent: {
    id: 'payment_result_config_cards_faq_content',
    defaultMessage: 'If you have any doubts or questions, this is the place.',
  },
  faqAction: {
    id: 'payment_result_config_cards_faq_action',
    defaultMessage: 'Learn more',
  },
}
